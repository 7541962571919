*{
    box-sizing: border-box;
    margin: 0;
}
:root{
    --heights: 100vh;
    --widths: 100vw;
}
.slider-container{
   position: fixed;
   top:0;
   left:0;
    margin: auto;
    overflow: hidden;
    height: var(--heights);
    width: var(--widths);
    z-index: -1;
    opacity: 0.5;
}
.slider-container .swiper{
    height: var(--heights);
    width: var(--widths);
}
.active{
    display: inline-block;
}
.inactive{
    display: none;
}
.slides{
    height: var(--heights);
    width: var(--widths);
    position: relative;
}
.slide-image{
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
}
.slide-title{
    width: 100%;
    height: 100%;
    color: white;
    font-size: 50px;
    position: absolute;
    text-align: center;
    top:40%;
    z-index: 10;
}
.prev, .next{
    cursor: pointer;
    z-index: 100;
    position: absolute;
    top:50%;
    width: auto;
    padding: 1rem;
    margin-top: -3rem;
    font-size: 30px;
    font-weight: bold;
    border-radius: 0 5px 5px 0;
}
.prev:hover, .next:hover{
    color:white;
    background: rgba(0, 0, 0, 0.6);
    transition: all 0.5s ease-in;
}
.next{
    right: 0;
    border-radius: 5px 0 0 5px;
}
.prev{
    left: 0;
}
.all-dots{
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    top:82%;
    justify-content: center;
    z-index: 200;
}
.dot{
    cursor: pointer;
    height: 1rem;
    width: 1rem;
    margin: 0 3px;
    /* background: rgba(0, 0, 0, 0.3); */
    background: var(--color-primary);
    border-radius: 50%;
    display: inline-block;
}
.active-dot, .dot:hover{
    /* background:rgba(255, 255, 255, 0.5); */
    background: white;
}

.fade-enter {
    opacity: 1;
  }
  .fade-exit {
    opacity: 0;
    
  }
  .fade-enter,
  .fade-exit {
    transition: opacity 500ms;
  }

  