header{
    height: 100vh;
    padding-top: 10rem;
    overflow: hidden;
}
.header__container{
    text-align: center;
    height: 50%;
    width: 100%;
    padding-top: 10rem;
    position: relative;
    background: linear-gradient(181deg, black, transparent, transparent);

}
.header__container .me{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}
.header__container .me .name-title{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap:0.5rem;
} 
.header__container .me .name-title h1{
    font-size: 2rem;
    font-weight: 300;
    margin-bottom: -5px;
}
.header__container .me .name-title .divider{
    height: 1px;
    width: 100%;
    background: var(--color-primary);
}
.header__container .me .logo{
    max-width: 70px;
}
.header-cta{
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}
.main-image{
    background: var(--color-primary);
    width: 22rem;
    height: 30rem;
    position: absolute;
    left:calc(50% - 11rem);
    margin-top: 4rem;
    border-radius: 12rem 12rem 0 0;
    overflow: hidden;
    padding: 1.9rem 1.5rem 0rem 1.5rem;
}
.scroll__down{
    position: absolute;
    right: 2rem;
    bottom:6rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.8rem;
    padding: 0.5rem 0.8rem;
    border-radius: 5px;
    background: rgba(0,0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    gap:0.5rem
}
.scroll__down .chevron{
    font-size: 1rem;
    color: var(--color-primary);
}


/* ======================== RESPONSIVE ===================== */
/*Medium Devices*/
@media screen and (max-width:1024px){
    .header__container{
        padding-top: 5rem;
    }
    header{
        height: 68vh;
    }   
}
/*Small Devices*/
@media screen and (max-width:600px){
    
    header{
         height: 40vh;
    }
    .scroll__down{
        display: none;
    }       
    
}