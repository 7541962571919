.portfolio__container{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
}
.portfolio__item{
    background: var(--color-bg-variant);
    padding: 1.3rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition-all);
    backdrop-filter: blur(15px);
    -webkit-box-shadow: var(--drop-shadow);
    -moz-box-shadow: var(--drop-shadow);
    box-shadow: var(--drop-shadow);
}
.portfolio__item:hover{
    border-color:var(--color-primary-variant);
    background: transparent;
}
.portfolio__item h3{
    margin: 1.2rem 0 0 0;
}
.portfolio__container .portfolio__item small{
    font-weight: 100;
    font-size: 0.8rem;
    margin: 1.2rem 0;
}
.portfolio__image{
    border-radius: 1.5rem;
    overflow: hidden;
}
.portfolio__container .portfolio__item a{
    margin-top: 10px;
}

/* ======================== RESPONSIVE ===================== */
/*Medium Devices*/
@media screen and (max-width:1024px){
    .portfolio__container{
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }

    
}
/*Small Devices*/
@media screen and (max-width:600px){
    .portfolio__container{
        grid-template-columns: 1fr;
        gap: 1rem;
    }
       
}