footer{
    background: var(--color-primary);
    padding: 3rem 0 8rem 0;
    text-align: center;
    font-size: 0.9rem;
    margin-top: 7rem;
}
footer .links{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    margin: 0 auto;
}
footer .links a{
    color: var(--color-bg);
}
.footer__logo{
    color: var(--color-bg);
    font-size: 1.7rem;
    font-weight: 600;
    margin-bottom: 1rem;
}
.footer__copyright{
    margin-top: 1rem;
    font-size: 0.8rem;
}