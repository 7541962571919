#contact{
    margin-bottom: 7rem;
}
.container.contact__container{
    width: 58%;
    display: grid;
    grid-template-columns: 30% 58%;
    gap: 12%;
}

.contact__options{
    display: flex;
    flex-direction: column;
    gap:4.2rem;
}
.contact__option{
    background: var(--color-bg-variant);
    padding: 1.2rem;
    border-radius: 1.2rem;
    text-align: center;
    border:1px solid transparent;
    transition: var(--transition-all);
    backdrop-filter: blur(15px);
    -webkit-box-shadow: var(--drop-shadow);
    -moz-box-shadow: var(--drop-shadow);
    box-shadow: var(--drop-shadow);
}
.contact__option:hover{
    background: transparent;
    border-color: var(--color-primary-variant);
}
.contact__option_icon{
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}
.contact__option a{
    margin-top: 0.7rem;
    display: inline-block;
    font-size: 0.8rem;
}

form{
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}
input, textarea{
  /*   width: 100%; */
    padding: 1.5rem;
    border-radius: 0.5rem;
    background: transparent;
    border: 2px solid var(--color-primary-variant);
    resize: none;
    color: var(--color-white); 
}
/* ======================== RESPONSIVE ===================== */
/*Medium Devices*/
@media screen and (max-width:1024px){
   .container.contact__container{
    grid-template-columns: 1fr;
    gap: 2rem;
   }
}
/*Small Devices*/
@media screen and (max-width:600px){
    .container.contact__container{
       width: var(--container-width-sm);
     }
     .container.contact__container form button{
        width: 100%;
     }
     .contact__options{
        gap:1.2rem;
    }
}
  .showModal{
    display: block;
    position: fixed; /* Stay in place */
    z-index: 1; 
    left: 0;
    top: 0;
    width: 100%; 
    height: 100%; 
    overflow: auto; 
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  }
  .closeModal{
    display: none;
  }
  
  /* Modal Content/Box */
  .modal-content {
    background-color: var(--color-bg-variant);
    border-radius: 3rem;
    text-align: center;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 300px; 
  }
