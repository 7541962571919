
nav .links{
    background: rgba(0, 0, 0, 0.3);
    width: max-content;
    display: block;
    z-index: 500;
    position: fixed;
    padding: 0.7rem 1.7rem;
    left:50%;
    bottom: 2rem;
    transform: translateX(-50%);
    display: flex;
    gap: 0.8rem;
    border-radius: 3rem;
    backdrop-filter: blur(10px);
}
nav .links a{
    background: transparent;
    padding: 0.9rem;
    border-radius: 50%;
    display: flex;
    color: var(--color-light);
    font-size: 1.1rem;
}
nav .links a:hover{
   background: rgba(0, 0, 0, 0.3); 
}

nav .links a.active{
    background: var(--color-bg);
    color: var(--color-white);
}