@import "variables.css"; 
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, header, hgroup, 
menu,  output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
    box-sizing: border-box;
    outline: 0;
    text-decoration: none;
}


body {
	line-height: 1.7;
    font-family: 'Poppins', sans-serif;
    background: var(--color-bg);
    color: var(--color-white);
    /* background-image: url(../src/assets/images/bg-texture.png); */

}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
html{
    scroll-behavior: smooth;
}
::-webkit-scrollbar{
    display: none;
}

/* ======================== GENERAL STYLEs ===================== */
h1, h2, h3, h4, h5{
    font-weight: 500;
}
h1{
    font-size: 2.5rem;
}
h2{
    font-size: 1.8rem;
}
section{
    margin-top: 8rem;
}
section > h2, section > h5{
    text-align: center;
    color:var(--color-light);
}
section > h2{
    color:var(--color-primary);
    margin-bottom: 3rem;
}
a{
    /* color:var(--color-light);*/
    color: var(--color-primary);
    transition: var(--transition-all);
}
a:hover{
    color:var(--color-white);
}

img{
    display: block;
    width: 100%;
    object-fit: cover;
}
.container{
    width:var(--container-width-lg);
    margin: 0 auto;
}
.text-light{
    color: var(--color-light);
}
.cta{
    color: var(--color-primary);
}
.btn{
    width: max-content;
    display: inline-block;
    color:var(--color-primary);
    padding: 0.75rem 1.2rem;
    border-radius: 0.4rem;
    cursor: pointer;
    border: 1px solid var(--color-primary);
    transition: var(--transition-all);
}

.btn:hover{
    background: var(--color-white);
    color: var(--color-bg);
    border-color: transparent;
}
.btn-primary{
    background: var(--color-primary);
    color: var(--color-bg);
}

/* ======================== RESPONSIVE ===================== */
/*Medium Devices*/
@media screen and (max-width:1024px){
    .container{
        width: var(--container-width-md);
    }
    section{
        margin: 6rem 1rem;
    }
}
/*Small Devices*/
@media screen and (max-width:600px){
    .container{
        width: var(--container-width-sm);
    }
    section > h2{
        margin-bottom: 2rem;
    }
    
}
