:root{
    /* --color-bg: #1f1f38; */
    --color-bg:rgb(34, 34, 34);
    --color-bg-variant: #2b385b;

    /* --color-bg-variant: #2c2c6c; */
    --color-primary: #4db5ff;
    --color-primary-variant: rgba(77,181,255,0.4);
    --color-white: #fff;
    --color-light: rgba(255,255,255,0.6);

    --color-dark: rgb(51, 51, 51);

    --container-width-lg: 85%;
   /*  --container-width-md:86%;
    --container-width-sm: 90%; */

    --drop-shadow: 10px 10px 37px -14px rgba(0,0,0,0.75);
    --transition-all: all 400ms ease;
}
/* #2b385b */